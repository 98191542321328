.filters-container__buttons_left {
  align-items: flex-start;
}
.filters-container-buttons__buttons{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (min-width: 1920px) and (max-width: 2500px){
  .filters-container-buttons__buttons {
    max-width: calc(1920px - 20%);
    margin: auto;
    padding: 0;
  }
}

@media (min-width: 2501px){
  .filters-container-buttons__buttons {
    max-width: 1920px;
    margin: auto;
    padding: 0;
  }
}
