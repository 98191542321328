.DateRangePicker_picker {
  z-index: 1000 !important;
}

.DateInput_input__small {
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: 0;
  padding: 3px 6px 3px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
}

.DateInput,
.DateInput_input,
.DateInput_input__focused {
  background: transparent;
}

.DateRangePickerInput__withBorder {
  border-radius: 5px;
  border: 1px solid #203864;
}

.DateRangePickerInput > .DateInput:first-of-type {
  margin-left: 2rem;
}

.DateRangePickerInput > .DateInput:first-of-type::before {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../assets/svgs/calendar.svg");
  background-size: contain;
  position: absolute;
  left: -1.5rem;
  background-repeat: no-repeat;
  top: 3px;
}

.DateInput_input {
  border-bottom: 0;
}

.DateInput_input__focused {
  border-bottom: 0;
}

.DayPicker__hidden {
  visibility: visible;
}

.CalendarDay__selected_span {
  background: #203864 !important;
  border: 1px double #aaaaaa;
  color: white !important;
}

.CalendarDay__selected {
  background: #203864;
  border: 1px double #4d4d4d;
  color: white !important;
}

.CalendarDay__selected_span:hover {
  background: #577590 !important;

  color: #fff;
}

.DateRangePicker_picker {
  top: 30px !important;
  left: -9rem !important;
}

.CalendarMonth_caption {
  color: #484848;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 60px;
  caption-side: initial;
}

.CalendarDay__selected_span {
  background: #dddddd;
  border: 1px double #aaaaaa;
  color: #484848;
}

.CalendarMonth {
  background: #fff;
}

.CalendarDay__default {
  color: #484848;
}

.DayPicker_wrapper__horizontal {
  margin-top: 0px;
}

.DayPicker_calendarInfo__horizontal {
  margin-top: 10px;
}

.CalendarDay__blocked_out_of_range {
  background: #cacccd !important;
}

.DateInput__small {
  width: 120px;
  text-align: center !important;
}

.DateRangePickerInput_arrow {
  margin-left: 0px;
  margin-right: 0px;
}

.DateInput_input {
  text-align: center !important;
  margin-left: -5px;
}
