#svg-container {
  width: 100%;
  height: 300px;
  text-align: center;
}

#svg-container > svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 1920px){
  #svg-container {
    height: 400px;
  }
}

@media (min-width: 2500px){
  #svg-container {
    height: 520px;
  }
}


svg:not(:root) {
  overflow: visible;
}

.tooltip {
  min-width: 50px;
  padding: 10px 20px;
  color: #444444;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

#country_name {
  text-align: center;
  font-weight: bold;
}

.divTableCell {
  border: 0 !important;
}

.svg-map-zoom {
  position: absolute;
  bottom: 4rem;
  left: 0;
  display: flex;
  flex-direction: column;
}

.svg-map-zoom__control {
  border: 1px solid #bbb;
  border-radius: 4px;
  font-size: 1.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  cursor: pointer;
}

.svg-map-zoom__control--plus {
  margin-bottom: .5rem;
}

.svg-map-zoom__control:hover,
.svg-map-reset:hover {
  background-color: #203864;
  color: #fff;
}

.svg-map-reset {
  position: absolute;
  bottom: 4rem;
  right: 0;
  border: 1px solid #bbb;
  border-radius: 4px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  cursor: pointer;
  padding: 1px 5px;
}
