/*     BASE STYLES     */
@font-face {
  font-family: Nanami;
  /* webpackIgnore: true */
  src: url("assets/fonts/nanami-med.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media only screen and (min-width: 1980px) and (max-width: 5500px) {
  html {
    font-size: 75%;
  }
}

body {
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  color: #272d3b;
}

.Main {
  display: grid;
  min-height: 100vh;
  justify-content: flex-start;
  grid-template-columns: 100vw;
  grid-template-rows: auto 1fr auto;
  max-width: 100vw;
  overflow-x: hidden;
}

select,
input,
textarea {
  outline: none;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     HEADER     */
.header {
  background-color: #2b2b2b;
  display: flex;
  justify-content: space-between;
  padding: 1rem 10%;
  background-image: url("assets/img/header-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .header {
    padding: 1rem 15%;
  }
}
@media only screen and (min-width: 2501px) {
  .header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: calc(calc(100vw - 1920px) / 2);
    padding-right: calc(calc(100vw - 1920px) / 2);
  }
}
.header--public-home {
  background-color: rgba(43, 43, 43, 0.82);
  background-image: none;
  position: absolute;
  width: 100%;
  justify-content: space-between;
}

.header--public-home > .header__title {
  font-size: 4.5rem;
  letter-spacing: 2px;
}
.header--public-home > .header__buttons {
  position: relative;
  right: 0;
}
.header--public-home > .header__buttons > .header__profile-link {
  width: auto;
  height: auto;
  background-color: transparent;
  color: #fff;
}
.header__balance {
  font-size: 0;
  background-color: transparent;
  display: block;
  width: 1rem;
  height: 1rem;
}
.header__logo {
  width: 4rem;
}
.header__title {
  font-family: "Nanami", sans-serif;
  color: #fff;
  font-size: 2.8rem;
  text-transform: lowercase;
}
.header--public-home img.logo {
  vertical-align: sub;
}
.header--internal img.logo {
  height: 48px;
  vertical-align: bottom;
}

@media only screen and (min-width: 2501px) {
  .header--public-home img.logo {
    height: 80px;
    vertical-align: sub;
  }
  .header--internal img.logo {
    height: 64px;
    vertical-align: bottom;
  }
}
.header__buttons {
  /*position: absolute;
    right: 10%;*/
  display: flex;
  align-items: center;
}
.header__profile-link {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8dcaff;
  margin-right: 3rem;
  text-decoration: none;
  color: #000;
  border-radius: 50%;
}
.header__logout-btn {
  max-width: 25px;
  display: flex;
  align-items: center;
}
.header__logout-btn-image {
  width: 100%;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     MAIN NAV     */
.main-nav {
  background-color: #203864;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 10;
  transform: translateY(-50%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 17rem;
  transition: all 0.4s ease;
}

@media (min-width: 1920px) {
  .main-nav {
    left: auto;
  }
}

.main-nav-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  list-style-type: none;
}
.main-nav-list__item {
  width: 3rem;
  height: 3rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1rem 0;
}
.main-nav-list__item--home {
  background-image: url("assets/svgs/home.svg");
}
.main-nav-list__item--download {
  background-image: url("assets/svgs/download.svg");
}
.main-nav-list__item--bookmark {
  background-image: url("assets/svgs/bookmark.svg");
}
.main-nav-list__item--save {
  background-image: url("assets/svgs/save.svg");
}
.main-nav-list__item--share {
  background-image: url("assets/svgs/shareside.svg");
}
.main-nav-list__item--save-add {
  background-image: url("assets/svgs/save-add.svg");
}
.main-nav-list__item--cite-multiple {
  background-image: url("assets/svgs/cite-multiple.svg");
}
.main-nav-list__link {
  width: 100%;
  height: 100%;
  display: block;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     FOOTER     */
.footer {
  background-color: #222;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0;
  font-size: 1.4rem;
}
.footer__links {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  color: #fff;
}
.footer__links > a {
  color: #ccc;
  text-decoration: none;
  margin: 0 2px;
}
.footer__copyright {
  color: #777;
  text-align: center;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     PUBLIC HOME     */
.public-home {
  background-image: url("assets/img/home-bg.jpg");
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding: 12rem 15% 10rem 10%;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-column-gap: 3rem;
  align-items: center;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .public-home {
    padding-top: 12rem;
    padding-left: 12%;
    padding-bottom: 10rem;
    padding-right: 12%;
    min-height: 90vh;
  }
}
@media only screen and (min-width: 2501px) {
  .public-home {
    padding-top: 10rem;
    padding-left: calc(calc(100vw - 1920px) / 2);
    padding-right: calc(calc(100vw - 1920px) / 2);
    align-content: flex-start;
    min-height: 90vh;
    overflow: hidden;
  }
}
.public-home > .cards-container {
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
}
.public-home > .cards-container > .card {
  background-color: rgba(248, 248, 248, 0.94);
}
.public-home__button {
  grid-column: 1/3;
  width: 27rem;
  justify-self: center;
  color: #fff;
  background-color: #203864;
  font-family: "Lato", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 10px;
  height: 4rem;
  border: 0;
  outline: 0;
  margin-top: 2rem;
  cursor: pointer;
  align-self: center;
}

.live-numbers {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2rem;
  font-family: "Lato", sans-serif;
  padding: 4rem 3.5rem !important;
}
.live-numbers__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.live-numbers__header > h2 {
  color: #203864;
  font-size: 6rem;
  font-weight: 900;
}
.live-numbers__header > b {
  color: #000;
  font-size: 2rem;
}
.live-numbers__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  list-style-type: none;
  grid-template-rows: 3.4rem 3.4rem 3.4rem;
  align-content: center;
}
.live-numbers__list-item {
  background-color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 6px;
  height: 3.4rem;
  align-items: center;
  position: relative;
  padding-left: 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .live-numbers__list-item {
    font-size: 1.3rem;
  }
}
@media only screen and (min-width: 2501px) {
  .live-numbers__list-item {
    font-size: 1.3rem;
  }
}
.live-numbers__list-item > b {
  font-size: 1.3rem;
  font-weight: 900;
  color: #272d3b;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .live-numbers__list-item > b {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 2501px) {
  .live-numbers__list-item > b {
    font-size: 1.5rem;
  }
}
.live-numbers__list-item::before {
  content: "";
  display: block;
  height: 100%;
  width: 10px;
  position: absolute;
  left: 0;
  border-radius: 5px;
}
.live-numbers__list-item--green::before {
  background-color: #22b82b;
}
.live-numbers__list-item--purple::before {
  background-color: #702aee;
}
.live-numbers__list-item--orange::before {
  background-color: #f76b1c;
}
.live-numbers__list-item--yellow::before {
  background-color: #ffbb00;
}
.live-numbers__list-item--red::before {
  background-color: #d42929;
}
.live-numbers__list-item--blue::before {
  background-color: #008ef8;
}

.public-home-text {
  font-family: "Lato", sans-serif;
  padding: 3rem 4.5rem !important;
}
.public-home-text > h3 {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .public-home-text > h3 {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 2501px) {
  .public-home-text > h3 {
    font-size: 1.5rem;
  }
}
.public-home-text > p {
  columns: 2;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  column-gap: 2rem;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .public-home-text > p {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 2501px) {
  .public-home-text > p {
    font-size: 1.4rem;
  }
}

.updates-container {
  max-height: 44rem;
  display: flex;
  flex-direction: column;
}

.update {
  background-color: rgba(248, 248, 248, 0.94);
  border-radius: 10px;
  margin: 0 1.25rem 1rem;
  padding: 1.2rem 6.5rem 2.5rem 4.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.8s ease;
  transform: scale(1.15);
  width: 86%;
  margin-left: 7%;
}
.update--mid {
  width: 86%;
  transform: scale(1);
  margin-left: 7%;
  opacity: 0.8;
}
.update--low {
  width: 86%;
  transform: scale(1);
  margin-left: 7%;
  opacity: 0.6;
}
.update__flag {
  position: absolute;
  top: 1.2rem;
  left: 3%;
  width: 2rem;
}
.update__title {
  font-size: 1.15rem;
  font-weight: bold;
}
.update__country {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}
.update__button {
  position: absolute;
  bottom: 1.2rem;
  right: 3%;
  width: 3rem;
  height: 3rem;
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: none;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
  z-index: 1;
  background-image: url("assets/svgs/go-to.svg");
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     FILTERS AND SEARCHBAR     */
.filters-container {
  background-color: #efefef;
  padding: 3.5rem 10% 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .filters-container {
    padding: 3.5rem 0% 2.5rem;
  }
}
@media only screen and (min-width: 2501px) {
  .filters-container {
    padding: 3.5rem 0% 2.5rem;
  }
}
.filters-container > *:not(:last-child) {
  margin-bottom: 1.2rem;
}
.filters-container__buttons {
  display: flex;
  align-self: flex-end;
}
.filters-container__button,
.filters-container__button--apply {
  font-size: 1.15rem;
  padding: 2px 4px;
  border: 0;
  font-family: "Lato", sans-serif;
}
.filters-container__button:not(:last-child),
.filters-container__button--apply:not(:last-child) {
  margin-right: 5px;
}
.filters-container__button:hover,
.filters-container__button--apply:hover {
  cursor: pointer;
  font-weight: bold;
}
.filters-container__button--apply {
  background-color: #8497b0;
  border-radius: 10px;
  border: 0;
  outline: none;
  color: #fff;
  padding: 3px 8px;
}

.filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .filters {
    max-width: calc(1920px - 20%);
    margin: auto;
  }
}
@media only screen and (min-width: 2501px) {
  .filters {
    max-width: 1920px;
    margin: auto;
  }
}
.filters__filter {
  width: 100%;
  padding: 0.8rem 2rem;
  color: #fff;
  border: 0;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  background-image: url("assets/img/dropdown-arrow.png");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position-x: 93%;
  background-position-y: center;
  font-size: 1.4rem;
}
.filters__filter--yellow {
  background-color: #ffbb00;
}
.filters__filter--orange {
  background-color: #f76b1c;
}
.filters__filter--red {
  background-color: #d42929;
}
.filters__filter--green {
  background-color: #22b82b;
}
.filters__filter--blue {
  background-color: #008ef8;
}
.filters__filter--purple {
  background-color: #702aee;
}

.search-and-tags-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  padding: 2rem;
  position: relative;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .search-and-tags-container {
    max-width: calc(1920px - 20%);
    margin: auto;
  }
}
@media only screen and (min-width: 2501px) {
  .search-and-tags-container {
    max-width: 1920px;
    margin: auto;
  }
}
.search-and-tags-container__searchbar {
  border-bottom: 1px solid #dcdcdc;
  background-image: url("assets/svgs/magnifying-glass.svg");
  background-repeat: no-repeat;
  padding-left: 2rem;
  padding-bottom: 2px;
  font-family: "Lato", sans-serif;
  font-size: 1.35rem;
  background-position-y: 2px;
  background-size: 1.25rem 1.25rem;
}
.search-and-tags-container__tooltip {
  display: block;
  background-image: url("assets/svgs/tooltip.svg");
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  align-self: flex-end;
  transform: translateY(2rem);
  position: absolute;
  top: 0;
}
.search-and-tags-container__tags {
  display: flex;
  padding-top: 1rem;
  flex-wrap: wrap;
}
.search-and-tags-container__tag,
.criteria__tag {
  padding: 4px 10px;
  text-transform: uppercase;
  font-size: 1.1rem;
  border: 0;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 5px;
}
.search-and-tags-container__tag--yellow {
  background-color: #ffbb00;
}
.search-and-tags-container__tag--orange {
  background-color: #f76b1c;
}
.search-and-tags-container__tag--red {
  background-color: #d42929;
}
.search-and-tags-container__tag--green {
  background-color: #22b82b;
}
.search-and-tags-container__tag--blue {
  background-color: #008ef8;
}
.search-and-tags-container__tag--purple {
  background-color: #702aee;
}
.search-and-tags-container__tag:not(:last-child),
.criteria__tag:not(:last-child) {
  margin-right: 5px;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     HOMEPAGE CONTENT     */
.greeting {
  padding: 3.5rem 10% 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .greeting {
    padding: 3.5rem 0% 1rem;
    max-width: calc(1920px - 20%);
    margin: auto;
  }
}
@media only screen and (min-width: 2501px) {
  .greeting {
    padding: 3.5rem 0% 1rem;
    max-width: 1920px;
    margin: auto;
  }
}
.greeting__text > h1 {
  font-size: 3.3rem;
}
.greeting__text > h1 > span {
  color: #8497b0;
}
.greeting__text > p {
  font-size: 1.4rem;
}
.greeting__text > p > span {
  color: #8497b0;
}
.greeting__nav {
  display: flex;
  align-items: center;
}
.greeting__nav > a {
  text-decoration: none;
  color: #203864;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}
.greeting__nav > a:first-of-type {
  margin-right: 1rem;
}
.greeting__nav > a:last-of-type {
  margin-left: 1rem;
}
.greeting__nav > a:hover {
  text-decoration: underline;
}
.greeting__nav > a:hover > .greeting__link--folders {
  background-image: url("assets/svgs/save--hover.svg");
  width: 2.4rem;
  height: 2.4rem;
}
.greeting__nav > a:hover > .greeting__link--criteria {
  background-image: url("assets/svgs/bookmark--hover.svg");
  width: 2.4rem;
  height: 2.4rem;
}
.greeting__link {
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  display: inline-block;
  margin-right: 5px;
}
.greeting__link--folders {
  background-image: url("assets/svgs/save.svg");
}
.greeting__link--criteria {
  background-image: url("assets/svgs/bookmark.svg");
}

.cards-container {
  padding: 3.5rem 8%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .cards-container {
    padding: 3.5rem 0;
    max-width: calc(1920px - 16%);
    margin: 0 auto;
  }
}
@media only screen and (min-width: 2501px) {
  .cards-container {
    padding: 3.5rem 0;
    max-width: calc(1920px + 2%);
    margin: 0 auto;
  }
}

.card {
  background-color: #f8f8f8;
  border-radius: 20px;
  margin: 0 1.25rem 1.5rem;
  padding: 2rem 2.5rem;
}
.card__content svg text {
  font-family: "Lato", sans-serif !important;
}
.card__content--fixed-height {
  max-height: 350px;
  overflow-y: auto;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .card__content--fixed-height {
    max-height: 460px;
  }
}
@media only screen and (min-width: 2501px) {
  .card__content--fixed-height {
    max-height: 360px;
  }
}
.card__title {
  font-size: 1.55rem;
  font-weight: bold;
}
.card__title--bordered {
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 5px;
}
.card__subtitle {
  font-size: 1rem;
  display: block;
  margin-bottom: 5px;
}
.card__more,
.card__more--small {
  font-size: 1.3rem;
  color: #8497b0;
  text-decoration: none;
  font-weight: bold;
  margin: 1.5rem 0 0;
  display: block;
}
.card__more--small {
  font-size: 1.1rem;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     CARDS WIDTH     */
.card--0 {
  width: calc(0% - 2.5rem);
}

.card--1 {
  width: calc(1% - 2.5rem);
}

.card--2 {
  width: calc(2% - 2.5rem);
}

.card--3 {
  width: calc(3% - 2.5rem);
}

.card--4 {
  width: calc(4% - 2.5rem);
}

.card--5 {
  width: calc(5% - 2.5rem);
}

.card--6 {
  width: calc(6% - 2.5rem);
}

.card--7 {
  width: calc(7% - 2.5rem);
}

.card--8 {
  width: calc(8% - 2.5rem);
}

.card--9 {
  width: calc(9% - 2.5rem);
}

.card--10 {
  width: calc(10% - 2.5rem);
}

.card--11 {
  width: calc(11% - 2.5rem);
}

.card--12 {
  width: calc(12% - 2.5rem);
}

.card--13 {
  width: calc(13% - 2.5rem);
}

.card--14 {
  width: calc(14% - 2.5rem);
}

.card--15 {
  width: calc(15% - 2.5rem);
}

.card--16 {
  width: calc(16% - 2.5rem);
}

.card--17 {
  width: calc(17% - 2.5rem);
}

.card--18 {
  width: calc(18% - 2.5rem);
}

.card--19 {
  width: calc(19% - 2.5rem);
}

.card--20 {
  width: calc(20% - 2.5rem);
}

.card--21 {
  width: calc(21% - 2.5rem);
}

.card--22 {
  width: calc(22% - 2.5rem);
}

.card--23 {
  width: calc(23% - 2.5rem);
}

.card--24 {
  width: calc(24% - 2.5rem);
}

.card--25 {
  width: calc(25% - 2.5rem);
}

.card--26 {
  width: calc(26% - 2.5rem);
}

.card--27 {
  width: calc(27% - 2.5rem);
}

.card--28 {
  width: calc(28% - 2.5rem);
}

.card--29 {
  width: calc(29% - 2.5rem);
}

.card--30 {
  width: calc(30% - 2.5rem);
}

.card--31 {
  width: calc(31% - 2.5rem);
}

.card--32 {
  width: calc(32% - 2.5rem);
}

.card--33 {
  width: calc(33% - 2.5rem);
}

.card--34 {
  width: calc(34% - 2.5rem);
}

.card--35 {
  width: calc(35% - 2.5rem);
}

.card--36 {
  width: calc(36% - 2.5rem);
}

.card--37 {
  width: calc(37% - 2.5rem);
}

.card--38 {
  width: calc(38% - 2.5rem);
}

.card--39 {
  width: calc(39% - 2.5rem);
}

.card--40 {
  width: calc(40% - 2.5rem);
}

.card--41 {
  width: calc(41% - 2.5rem);
}

.card--42 {
  width: calc(42% - 2.5rem);
}

.card--43 {
  width: calc(43% - 2.5rem);
}

.card--44 {
  width: calc(44% - 2.5rem);
}

.card--45 {
  width: calc(45% - 2.5rem);
}

.card--46 {
  width: calc(46% - 2.5rem);
}

.card--47 {
  width: calc(47% - 2.5rem);
}

.card--48 {
  width: calc(48% - 2.5rem);
}

.card--49 {
  width: calc(49% - 2.5rem);
}

.card--50 {
  width: calc(50% - 2.5rem);
}

.card--51 {
  width: calc(51% - 2.5rem);
}

.card--52 {
  width: calc(52% - 2.5rem);
}

.card--53 {
  width: calc(53% - 2.5rem);
}

.card--54 {
  width: calc(54% - 2.5rem);
}

.card--55 {
  width: calc(55% - 2.5rem);
}

.card--56 {
  width: calc(56% - 2.5rem);
}

.card--57 {
  width: calc(57% - 2.5rem);
}

.card--58 {
  width: calc(58% - 2.5rem);
}

.card--59 {
  width: calc(59% - 2.5rem);
}

.card--60 {
  width: calc(60% - 2.5rem);
}

.card--61 {
  width: calc(61% - 2.5rem);
}

.card--62 {
  width: calc(62% - 2.5rem);
}

.card--63 {
  width: calc(63% - 2.5rem);
}

.card--64 {
  width: calc(64% - 2.5rem);
}

.card--65 {
  width: calc(65% - 2.5rem);
}

.card--66 {
  width: calc(66% - 2.5rem);
}

.card--67 {
  width: calc(67% - 2.5rem);
}

.card--68 {
  width: calc(68% - 2.5rem);
}

.card--69 {
  width: calc(69% - 2.5rem);
}

.card--70 {
  width: calc(70% - 2.5rem);
}

.card--71 {
  width: calc(71% - 2.5rem);
}

.card--72 {
  width: calc(72% - 2.5rem);
}

.card--73 {
  width: calc(73% - 2.5rem);
}

.card--74 {
  width: calc(74% - 2.5rem);
}

.card--75 {
  width: calc(75% - 2.5rem);
}

.card--76 {
  width: calc(76% - 2.5rem);
}

.card--77 {
  width: calc(77% - 2.5rem);
}

.card--78 {
  width: calc(78% - 2.5rem);
}

.card--79 {
  width: calc(79% - 2.5rem);
}

.card--80 {
  width: calc(80% - 2.5rem);
}

.card--81 {
  width: calc(81% - 2.5rem);
}

.card--82 {
  width: calc(82% - 2.5rem);
}

.card--83 {
  width: calc(83% - 2.5rem);
}

.card--84 {
  width: calc(84% - 2.5rem);
}

.card--85 {
  width: calc(85% - 2.5rem);
}

.card--86 {
  width: calc(86% - 2.5rem);
}

.card--87 {
  width: calc(87% - 2.5rem);
}

.card--88 {
  width: calc(88% - 2.5rem);
}

.card--89 {
  width: calc(89% - 2.5rem);
}

.card--90 {
  width: calc(90% - 2.5rem);
}

.card--91 {
  width: calc(91% - 2.5rem);
}

.card--92 {
  width: calc(92% - 2.5rem);
}

.card--93 {
  width: calc(93% - 2.5rem);
}

.card--94 {
  width: calc(94% - 2.5rem);
}

.card--95 {
  width: calc(95% - 2.5rem);
}

.card--96 {
  width: calc(96% - 2.5rem);
}

.card--97 {
  width: calc(97% - 2.5rem);
}

.card--98 {
  width: calc(98% - 2.5rem);
}

.card--99 {
  width: calc(99% - 2.5rem);
}

.card--100 {
  width: calc(100% - 2.5rem);
}

@media (max-width: 768px) {
  .card--0 {
    width: calc(50% - 2.5rem);
  }
  .card--1 {
    width: calc(50% - 2.5rem);
  }
  .card--2 {
    width: calc(50% - 2.5rem);
  }
  .card--3 {
    width: calc(50% - 2.5rem);
  }
  .card--4 {
    width: calc(50% - 2.5rem);
  }
  .card--5 {
    width: calc(50% - 2.5rem);
  }
  .card--6 {
    width: calc(50% - 2.5rem);
  }
  .card--7 {
    width: calc(50% - 2.5rem);
  }
  .card--8 {
    width: calc(50% - 2.5rem);
  }
  .card--9 {
    width: calc(50% - 2.5rem);
  }
  .card--10 {
    width: calc(50% - 2.5rem);
  }
  .card--11 {
    width: calc(50% - 2.5rem);
  }
  .card--12 {
    width: calc(50% - 2.5rem);
  }
  .card--13 {
    width: calc(50% - 2.5rem);
  }
  .card--14 {
    width: calc(50% - 2.5rem);
  }
  .card--15 {
    width: calc(50% - 2.5rem);
  }
  .card--16 {
    width: calc(50% - 2.5rem);
  }
  .card--17 {
    width: calc(50% - 2.5rem);
  }
  .card--18 {
    width: calc(50% - 2.5rem);
  }
  .card--19 {
    width: calc(50% - 2.5rem);
  }
  .card--20 {
    width: calc(50% - 2.5rem);
  }
  .card--21 {
    width: calc(50% - 2.5rem);
  }
  .card--22 {
    width: calc(50% - 2.5rem);
  }
  .card--23 {
    width: calc(50% - 2.5rem);
  }
  .card--24 {
    width: calc(50% - 2.5rem);
  }
  .card--25 {
    width: calc(50% - 2.5rem);
  }
  .card--26 {
    width: calc(50% - 2.5rem);
  }
  .card--27 {
    width: calc(50% - 2.5rem);
  }
  .card--28 {
    width: calc(50% - 2.5rem);
  }
  .card--29 {
    width: calc(50% - 2.5rem);
  }
  .card--30 {
    width: calc(50% - 2.5rem);
  }
  .card--31 {
    width: calc(100% - 2.5rem);
  }
  .card--32 {
    width: calc(100% - 2.5rem);
  }
  .card--33 {
    width: calc(100% - 2.5rem);
  }
  .card--34 {
    width: calc(100% - 2.5rem);
  }
  .card--35 {
    width: calc(100% - 2.5rem);
  }
  .card--36 {
    width: calc(100% - 2.5rem);
  }
  .card--37 {
    width: calc(100% - 2.5rem);
  }
  .card--38 {
    width: calc(100% - 2.5rem);
  }
  .card--39 {
    width: calc(100% - 2.5rem);
  }
  .card--40 {
    width: calc(100% - 2.5rem);
  }
  .card--41 {
    width: calc(100% - 2.5rem);
  }
  .card--42 {
    width: calc(100% - 2.5rem);
  }
  .card--43 {
    width: calc(100% - 2.5rem);
  }
  .card--44 {
    width: calc(100% - 2.5rem);
  }
  .card--45 {
    width: calc(100% - 2.5rem);
  }
  .card--46 {
    width: calc(100% - 2.5rem);
  }
  .card--47 {
    width: calc(100% - 2.5rem);
  }
  .card--48 {
    width: calc(100% - 2.5rem);
  }
  .card--49 {
    width: calc(100% - 2.5rem);
  }
  .card--50 {
    width: calc(100% - 2.5rem);
  }
  .card--51 {
    width: calc(100% - 2.5rem);
  }
  .card--52 {
    width: calc(100% - 2.5rem);
  }
  .card--53 {
    width: calc(100% - 2.5rem);
  }
  .card--54 {
    width: calc(100% - 2.5rem);
  }
  .card--55 {
    width: calc(100% - 2.5rem);
  }
  .card--56 {
    width: calc(100% - 2.5rem);
  }
  .card--57 {
    width: calc(100% - 2.5rem);
  }
  .card--58 {
    width: calc(100% - 2.5rem);
  }
  .card--59 {
    width: calc(100% - 2.5rem);
  }
  .card--60 {
    width: calc(100% - 2.5rem);
  }
  .card--61 {
    width: calc(100% - 2.5rem);
  }
  .card--62 {
    width: calc(100% - 2.5rem);
  }
  .card--63 {
    width: calc(100% - 2.5rem);
  }
  .card--64 {
    width: calc(100% - 2.5rem);
  }
  .card--65 {
    width: calc(100% - 2.5rem);
  }
  .card--66 {
    width: calc(100% - 2.5rem);
  }
  .card--67 {
    width: calc(100% - 2.5rem);
  }
  .card--68 {
    width: calc(100% - 2.5rem);
  }
  .card--69 {
    width: calc(100% - 2.5rem);
  }
  .card--70 {
    width: calc(100% - 2.5rem);
  }
  .card--71 {
    width: calc(100% - 2.5rem);
  }
  .card--72 {
    width: calc(100% - 2.5rem);
  }
  .card--73 {
    width: calc(100% - 2.5rem);
  }
  .card--74 {
    width: calc(100% - 2.5rem);
  }
  .card--75 {
    width: calc(100% - 2.5rem);
  }
  .card--76 {
    width: calc(100% - 2.5rem);
  }
  .card--77 {
    width: calc(100% - 2.5rem);
  }
  .card--78 {
    width: calc(100% - 2.5rem);
  }
  .card--79 {
    width: calc(100% - 2.5rem);
  }
  .card--80 {
    width: calc(100% - 2.5rem);
  }
  .card--81 {
    width: calc(100% - 2.5rem);
  }
  .card--82 {
    width: calc(100% - 2.5rem);
  }
  .card--83 {
    width: calc(100% - 2.5rem);
  }
  .card--84 {
    width: calc(100% - 2.5rem);
  }
  .card--85 {
    width: calc(100% - 2.5rem);
  }
  .card--86 {
    width: calc(100% - 2.5rem);
  }
  .card--87 {
    width: calc(100% - 2.5rem);
  }
  .card--88 {
    width: calc(100% - 2.5rem);
  }
  .card--89 {
    width: calc(100% - 2.5rem);
  }
  .card--90 {
    width: calc(100% - 2.5rem);
  }
  .card--91 {
    width: calc(100% - 2.5rem);
  }
  .card--92 {
    width: calc(100% - 2.5rem);
  }
  .card--93 {
    width: calc(100% - 2.5rem);
  }
  .card--94 {
    width: calc(100% - 2.5rem);
  }
  .card--95 {
    width: calc(100% - 2.5rem);
  }
  .card--96 {
    width: calc(100% - 2.5rem);
  }
  .card--97 {
    width: calc(100% - 2.5rem);
  }
  .card--98 {
    width: calc(100% - 2.5rem);
  }
  .card--99 {
    width: calc(100% - 2.5rem);
  }
  .card--100 {
    width: calc(100% - 2.5rem);
  }
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     ENTRIES PAGE    */
.entries-container {
  padding: 6rem 10% 3.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .entries-container {
    padding: 6rem 0% 3.5rem;
    max-width: calc(1920px - 20%);
    margin: auto;
  }
}
@media only screen and (min-width: 2501px) {
  .entries-container {
    padding: 6rem 0% 3.5rem;
    max-width: 1920px;
    margin: auto;
  }
}

.entries-sorting {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10%;
  transform: translateY(-1rem);
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .entries-sorting {
    right: 0;
  }
}
@media only screen and (min-width: 2501px) {
  .entries-sorting {
    right: 0;
  }
}
.entries-sorting__save {
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("assets/svgs/save.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 5px;
  outline: none;
  border: 0;
  background-color: transparent;
}
.entries-sorting__share {
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("assets/svgs/share.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 5px;
  outline: none;
  border: 0;
  background-color: transparent;
}
.entries-sorting__date {
  border-radius: 5px;
  border: 1px solid #203864;
  padding: 4px 0.8rem;
  margin: 0 5px;
  width: 20rem;
  display: flex;
  align-items: center;
}
.entries-sorting__date::before {
  content: "";
  background-image: url("assets/svgs/calendar.svg");
  width: 1.6rem;
  height: 1.6rem;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 5px;
}
.entries-sorting__dropdown {
  position: relative;
}
.entries-sorting__dropdown > p {
  background-color: #203864;
  border-radius: 5px;
  color: #fff;
  padding: 4px 0.8rem;
  margin: 0 5px;
  width: 10rem;
  cursor: pointer;
}
.entries-sorting__dropdown > p:hover + ul {
  max-height: none;
  margin-left: 5px;
  padding: 1rem 2rem;
}
.entries-sorting__dropdown > ul {
  max-height: 0;
  position: absolute;
  overflow: hidden;
  background-color: #f8f8f8;
  width: calc(100% - 10px);
  list-style-type: none;
  border-radius: 5px;
}
.entries-sorting__dropdown > ul:hover {
  max-height: none;
  margin-left: 5px;
  padding: 1rem 2rem;
}
.entries-sorting__dropdown > ul > li {
  padding: 0.5rem 0;
  cursor: pointer;
}
.entries-sorting__dropdown > ul > li:not(:last-of-type) {
  border-bottom: 1px solid #ddd;
}

.entries-accordion {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 1rem;
  padding-top: 2rem;
  margin-top: 3rem;
}

.entries-accordion ~ .entries-accordion {
  margin-top: 1rem;
}

.entries-accordion__control {
  display: none;
}
.entries-accordion__control + .entries-accordion-content {
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  margin: 0;
}
.entries-accordion__control:checked + .entries-accordion-content {
  max-height: none;
  transition: max-height 0.5s;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.entries-accordion__toggle {
  color: #203864;
  font-size: 1.5rem;
  border: 1px solid #203864;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 1.5rem;
  cursor: pointer;
  position: relative;
  bottom: 4rem;
}
.entries-accordion__title {
  font-size: 1.35rem;
  font-weight: bold;
  padding-left: 3rem;
  cursor: pointer;
  position: absolute;
  padding-bottom: 3.5rem;
}

.entries-accordion-content {
  width: 100%;
  padding: 0;
}

.main-entry {
  position: relative;
  width: 97%;
  margin-left: 3%;
  background-color: #f8f8f8;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 15% 39% 18% 18% 10%;
  padding: 1rem 1.3rem 1rem 0;
}

.main-entry-used {
  background-color: #e3e9f1;
}

.dog-entry {
  background-color: #fff5fb;
}
.pld-entry {
  background-color: #12408329;
}
.search-criteria-accordion-content--folders .main-entry {
  width: 100%;
  margin-left: 0%;
}

.main-entry__col {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  height: 11rem;
  overflow-y: auto;
}
.main-entry__col:not(:last-of-type) {
  border-right: 1px solid rgba(112, 112, 112, 0.5);
}
.main-entry__col--country {
  justify-content: flex-start;
}
.main-entry__col--meta {
  justify-content: center;
}
.main-entry__col--tags {
  /*align-items: center;
      flex-wrap: wrap;*/
  padding: 10px 1.5rem;
  /*flex-direction: row;*/
  justify-items: flex-start;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.8rem;
  align-items: center;
  overflow: overlay;
}
.main-entry__col--actions {
  display: grid;
  grid-template-columns: 3rem 3rem;
  grid-template-rows: 3rem 3rem;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}

.child-entry-container__control {
  display: none;
}
.child-entry-container__control + .child-entry-container {
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  margin: 0;
}
.child-entry-container__control:checked + .child-entry-container {
  max-height: none;
  transition: max-height 0.5s;
  overflow: hidden;
  margin: 0;
}

label.entry__meta {
  cursor: pointer;
}

.child-entry {
  background-color: #f8f8f8;
  margin-left: 17.5%;
  margin-right: 17%;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 57.8% 26.9% 16%;
  align-items: center;
  margin-top: 0.8rem;
  position: relative;
  padding: 1rem 1.3rem 1rem 0;
}
.child-entry__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  height: 11rem;
  overflow-y: auto;
}
.child-entry__col:not(:last-of-type) {
  border-right: 1px solid rgba(112, 112, 112, 0.5);
}
.child-entry__col--actions {
  display: grid;
  grid-template-columns: 3rem 3rem;
  grid-template-rows: 3rem 3rem;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}
.child-entry > .entry__checkbox {
  left: -8%;
}

.entry {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.entry__checkbox {
  border: 1px solid #203864;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  margin-right: 1.5rem;
  cursor: pointer;
  position: absolute;
  left: -2.5%;
  top: 50%;
  transform: translateY(-50%);
}
.entry__checkbox:checked {
  background-color: #203864;
  background-image: url("assets/img/tick.png");
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center;
}
.entry__country-container {
  display: flex;
  align-items: center;
}
.entry__country-container:first-of-type {
  margin: auto 0 0.8rem;
}
.entry__country-container:not(:first-of-type) {
  margin-bottom: 0.8rem;
}
.entry__country-container:last-of-type {
  margin: 0 0 auto;
}
.entry__country-container:only-of-type {
  margin: auto 0;
}
.entry__country-flag {
  width: 2.8rem;
  margin-right: 1rem;
}
.entry__country-flag--small {
  width: 1.5rem;
  margin-right: 6px;
}
.entry__country-name {
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
  text-decoration: none;
  color: #484848;
}
.entry__country-name:hover {
  text-decoration: underline;
}
.entry__date {
  font-size: 1rem;
  font-weight: bold;
  margin: auto 0 0;
}
.entry__title {
  color: #203864;
  font-size: 1.35rem;
}
.entry__title--margin {
  margin: 6px 0;
}
.entry__title--translation {
  font-size: 1.1rem;
  color: #8497b0;
  font-style: italic;
  font-weight: normal;
  margin: 0 0 6px;
}
.entry__content {
  font-size: 1.1rem;
  font-weight: regular;
  color: #272d3b;
  margin: 0 0 auto;
}
.entry__meta {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.8;
  color: #484848;
}
.entry__meta--bold {
  font-weight: 700;
}
.entry__meta--flex {
  display: flex;
  align-items: center;
}
.entry__meta--italic {
  font-style: italic;
}
.entry__meta--highlighted {
  color: #8497b0;
  font-weight: bold;
  font-size: 1.1rem;
}
.entry__tag {
  text-transform: uppercase;
  background-color: #8497b0;
  color: #fff;
  padding: 4px 8px;
  border-radius: 15px;
  font-size: 1rem;
  height: 2rem;
  margin-bottom: 3px;
  margin: 0 0.4rem 0.4rem 0;
  /*&:not(:last-of-type){
      margin-right: 5px;
    }


    &:not(:first-of-type) {
      margin-bottom: .8rem;
    }

    &:first-of-type {
      margin: auto .8rem .4rem 0;
    }

    &:last-of-type {
      margin: .4rem 0 auto 0;
    }

    &:only-of-type {
      align-self: center;
    }
*/
}
.entry__action {
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: unset;
}
.entry__action--cite {
  background-image: url("assets/svgs/cite.svg");
}
.entry__action--cite:hover {
  background-image: url("assets/svgs/cite--hover.svg");
}
.entry__action--save {
  background-image: url("assets/svgs/save.svg");
}
.entry__action--save:hover {
  background-image: url("assets/svgs/save--hover.svg");
}
.entry__action--remove {
  background-image: url("assets/svgs/trash.svg");
}
.entry__action--remove:hover {
  background-image: url("assets/svgs/trash--hover.svg");
}
.entry__action--share {
  background-image: url("assets/svgs/share.svg");
}
.entry__action--share:hover {
  background-image: url("assets/svgs/share--hover.svg");
}
.entry__action--report {
  background-image: url("assets/svgs/report.png");
}
.entry__action--report:hover {
  background-image: url("assets/svgs/report--hover.svg");
}

.entry--used > .main-entry {
  background-color: #e3e9f1;
}
.entry--used > .main-entry .entry__content,
.entry--used > .main-entry .entry__date {
  color: #486087;
}
.entry--used > .main-entry .entry__country-flag--small {
  filter: grayscale(1);
}
.entry--used > .main-entry .entry__action {
  background-color: #e3e9f1;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     SAVED SEARCH CRITERIA PAGE    */
.saved-search-criteria {
  padding: 3rem 12%;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .saved-search-criteria {
    max-width: 1920px;
    margin: auto;
  }
}
@media only screen and (min-width: 2501px) {
  .saved-search-criteria {
    padding: 3rem 0;
    max-width: 1920px;
    margin: auto;
  }
}
.saved-search-criteria__title {
  font-size: 2.6rem;
  font-weight: bold;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.saved-search-criteria__subtitle {
  font-size: 1.4rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.search-criteria-accordion {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 1rem;
  padding-top: 2rem;
  position: relative;
  /*&__control {
    display: none;

    & + .search-criteria-accordion-content {
      max-height: 0;
      transition: max-height .5s;
      overflow:hidden;
      margin: 0;
      padding-left: 2rem;
    }

    &:checked + .search-criteria-accordion-content {
      max-height: none;
      transition: max-height .5s;
      overflow:hidden;
      margin-bottom: 4rem;
      padding-left: 2rem;
    }
  }*/
}
.search-criteria-accordion:not(:first-of-type) {
  margin-top: 1rem;
}
.search-criteria-accordion-content {
  padding-bottom: 3rem;
  flex-direction: column;
  padding-left: 4.5rem;
}

.search-criteria-accordion-content--folders {
  padding-left: 0rem;
}
.search-criteria-accordion__toggle {
  color: #203864;
  font-size: 1.5rem;
  border: 1px solid #203864;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /*float: left;*/
  margin-right: 1.5rem;
  cursor: pointer;
  position: relative;
  bottom: 3.2rem;
}
.search-criteria-accordion__title {
  font-size: 1.8rem;
  font-weight: bold;
  padding-left: 4.5rem;
  cursor: pointer;
  position: absolute;
  padding-bottom: 2rem;
}
.search-criteria-accordion__notifications {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 3.2rem;
  height: 0rem;
}
.search-criteria-accordion__notification {
  width: 1.6rem;
  height: 1.7rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1rem 0;
  border: 0;
  outline: none;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
  z-index: 1;
  background-image: url("assets/svgs/bell.svg");
  position: relative;
  margin-right: 1rem;
}
.search-criteria-accordion__notification--delete {
  background-image: url("assets/svgs/trash-noborder.svg");
}
.search-criteria-accordion__notification--delete:hover {
  background-image: url("assets/svgs/trash-noborder-hover.svg");
}

.search-criteria-accordion__notification--notify {
  background-image: url("assets/svgs/notify.svg");
}
.search-criteria-accordion__notification--notify:hover {
  background-image: url("assets/svgs/notify-hover.svg");
}

.search-criteria-accordion__notification--stop-notify {
  background-image: url("assets/svgs/stop-notify.svg");
}
.search-criteria-accordion__notification--stop-notify:hover {
  background-image: url("assets/svgs/stop-notify-hover.svg");
}

.search-criteria-accordion__notification--active::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #f47f7f;
  top: 0;
  right: 0;
}
.search-criteria-accordion__actions {
  display: flex;
  justify-content: flex-end;
  height: 1.5rem;
  overflow: visible;
}
.search-criteria-accordion__action {
  width: 3rem;
  height: 3rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1rem 0;
  border: 0;
  outline: none;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
  z-index: 1;
}
.search-criteria-accordion__action:not(:last-of-type) {
  margin-right: 1rem;
}
.search-criteria-accordion__action--share {
  background-image: url("assets/svgs/share.svg");
}
.search-criteria-accordion__action--share:hover {
  background-image: url("assets/svgs/share--hover.svg");
}
.search-criteria-accordion__action--delete {
  background-image: url("assets/svgs/trash.svg");
}
.search-criteria-accordion__action--delete:hover {
  background-image: url("assets/svgs/trash--hover.svg");
}

.search-criteria-accordion__action--notify {
  background-image: url("assets/svgs/notify.svg");
}
.search-criteria-accordion__action--notify:hover {
  background-image: url("assets/svgs/notify-hover.svg");
}

.search-criteria-accordion__action--stop-notify {
  background-image: url("assets/svgs/stop-notify.svg");
}
.search-criteria-accordion__action--stop-notify:hover {
  background-image: url("assets/svgs/stop-notify-hover.svg");
}

.search-criteria-accordion__action--go-to {
  background-image: url("assets/svgs/go-to.svg");
}
.search-criteria-accordion__action--go-to:hover {
  background-image: url("assets/svgs/go-to--hover.svg");
}

.criteria {
  display: flex;
  min-height: 4rem;
  width: 35%;
  align-items: center;
}
.criteria:not(:last-of-type) {
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
}
.criteria__title {
  width: 13rem;
}
.criteria__tags {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  padding-top: calc(1rem - 5px);
  align-items: center;
  flex-shrink: 10000;
}
.criteria__tag {
  display: flex;
  margin-top: 5px;
}
.criteria__tag--language {
  background-color: #ffbb00;
}
.criteria__tag--topic {
  background-color: #f76b1c;
}
.criteria__tag--jurisdiction {
  background-color: #d42929;
}
.criteria__tag--source-type {
  background-color: #008ef8;
}
.criteria__tag--source {
  background-color: #22b82b;
}
.criteria__tag--source-location {
  background-color: #702aee;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     POPUP FORMS: GENERAL STYLING     */
.popup-wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  overflow: auto;
}

.popup-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  padding: 4rem 7rem;
  border-radius: 1.5rem;
  position: relative;
  font-size: 14px;
  top: 80px;
}

.popup-box .message-error {
  margin-bottom: 20px;
  color: red;
}

.popup-box .message-sent {
  margin-bottom: 20px;
  color: rgb(9, 0, 128);
}

.popup-box textarea {
  height: 125px;
}

.popup-box textarea,
.popup-box input {
  font-family: Lato, sans-serif;
}

@media only screen and (min-width: 1000px) {
  .popup-box {
    min-width: 600px;
  }
  .popup-box-small {
    min-width: unset;
    width: 400px;
  }
  .popup-box-med {
    min-width: unset;
    width: 600px;
  }
}
@media only screen and (min-width: 2000px) {
  .popup-box-small {
    min-width: unset;
    width: 600px;
  }
  .popup-box-med {
    min-width: unset;
    width: 800px;
  }
}

.popup-box--narrow {
  padding: 4rem;
}
.popup-box__close {
  border: 0;
  outline: 0;
  align-self: flex-end;
  background-color: transparent;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 4rem;
  font-weight: lighter;
  line-height: 3rem;
  opacity: 0.3;
  cursor: pointer;
}
.popup-box__title {
  font-family: "Nanami", sans-serif;
  font-size: 3rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
.popup-box__subtitle {
  font-size: 1.8rem;
  margin-top: 1.8rem;
  text-align: center;
}
.popup-box__subtitle--left {
  align-self: flex-start;
}
.popup-box__subtitle--center {
  text-align: center;
}
.popup-box__text {
  font-size: 1.4rem;
  text-align: center;
  margin: 4rem 0 2rem;
}
.popup-box__text--narrow {
  margin: 1rem 0;
}
.popup-box__steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  margin-bottom: 2rem;
}
.popup-box__step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-box__step > img {
  width: 5rem;
}
.popup-box__step > p {
  text-align: center;
}

.register-form,
.login-form,
.share-form,
.save-search-criteria-form,
.contact-form,
.profile-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.8rem;
  width: 110%;
}
.register-form__input,
.login-form__input,
.save-search-criteria-form__input,
.share-form__input,
.contact-form__input,
.profile-form__input {
  height: 3.8rem;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 1.6rem;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  color: rgba(28, 28, 28, 0.45);
  padding-left: 1.5rem;
  font-size: 1.35rem;
}
.register-form__input::placeholder,
.login-form__input::placeholder,
.save-search-criteria-form__input::placeholder,
.share-form__input::placeholder,
.contact-form__input::placeholder,
.profile-form__input::placeholder,
.register-form__input:-ms-input-placeholder,
.login-form__input:-ms-input-placeholder,
.save-search-criteria-form__input:-ms-input-placeholder,
.share-form__input:-ms-input-placeholder,
.contact-form__input:-ms-input-placeholder,
.profile-form__input:-ms-input-placeholder,
.register-form__input::-webkit-input-placeholder,
.login-form__input::-webkit-input-placeholder,
.save-search-criteria-form__input::-webkit-input-placeholder,
.share-form__input::-webkit-input-placeholder,
.contact-form__input::-webkit-input-placeholder,
.profile-form__input::-webkit-input-placeholder {
  color: rgba(28, 28, 28, 0.45);
}
.register-form__submit,
.login-form__submit,
.share-form__submit,
.confirm-popup__btn,
.contact-form__submit {
  height: 3.8rem;
  border-radius: 8px;
  padding: 0.8rem;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  background-color: #203864;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  border: 0;
  outline: none;
  font-size: 1.35rem;
  font-family: "Lato", sans-serif;
}

select.register-form__select {
  /* outline: none; */
  border: 0;
  -moz-appearance: auto;
  -webkit-appearance: auto;
  outline: none;
  background-color: #fff;
}

.popup-box textarea.register-form__textarea {
  height: 70px;
}

.login-form input.signup-radio {
  -webkit-appearance: auto;
  display: inline;
  margin-top: 2px;
}

.login-form .signup-row {
  width: 100%;
  display: flex;
}

.login-form .signup-label {
  padding-left: 2px;
  padding-top: 5px;
  flex-basis: 35%;
  padding: 0.8rem;
  margin-bottom: 1.6rem;
}

.login-form .signup-label-full {
  padding-left: 2px;
  padding-top: 5px;
  flex-basis: 100%;
  padding: 0.8rem;
  margin-bottom: 1.6rem;
}

.login-form .signup-input {
  padding-left: 2px;
  padding-bottom: 5px;
  width: 100%;
  flex-basis: 65%;
}

.login-form .signup-label-lg {
  flex-basis: 80%;
}

.login-form .signup-input-lg {
  flex-basis: 20%;
}

.login-form .signup-input-radio-group {
  display: flex;
  justify-content: space-between;
}
.login-form .signup-input-radio {
  text-align: center;
  display: flex;

  justify-content: space-between;
  margin-top: 5px;
}

.login-form .signup-input-radio label {
  margin-left: 5px;
}

::placeholder,
:-ms-input-placeholder,
:-webkit-input-placeholder {
  color: rgba(28, 28, 28, 0.45);
}

.login-form__input,
.save-search-criteria-form__input {
  margin: 0 1rem 1.6rem;
}

.forgotPassword {
  padding-bottom: 20px;
  text-align: left;
  width: 100%;
  font-size: 12px;
  margin-left: 20px;
  text-decoration: none;
  color: #203864;
}

.forgotPassword:hover {
  text-decoration: underline;
}

.login-form__submit {
  margin-bottom: 1.6rem;
}

.share-form {
  width: 30rem;
}
.share-form__label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  align-self: flex-start;
  padding-left: 1.5rem;
}
.share-form__input {
  margin: 0 1rem 1.6rem;
}
.share-form__submit {
  margin-bottom: 1.6rem;
}
.share-form__textarea,
.contact-form__textarea {
  font-family: "Lato", sans-serif !important;
  resize: none;
  height: 9rem;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 1.6rem;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  color: rgba(28, 28, 28, 0.45);
  padding-left: 1.5rem;
}
.share-form__textarea::placeholder,
.contact-form__textarea::placeholder,
.share-form__textarea:-ms-input-placeholder,
.contact-form__textarea:-ms-input-placeholder,
.share-form__textarea::-webkit-input-placeholder,
.contact-form__textarea::-webkit-input-placeholder {
  color: rgba(28, 28, 28, 0.45);
}

.confirm-popup__btns-holder {
  display: flex;
  margin-top: 1rem;
}
.confirm-popup__btns-holder > input {
  width: 6rem;
  height: 3rem;
  padding: 0.4rem;
}
.confirm-popup__btns-holder > input:first-of-type {
  margin-right: 0.6rem;
}

.confirm-popup__btn {
  margin-bottom: 0;
}

/*     SAVE FOLDERS POPUP     */
.folders-accordion {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.folders-accordion__control {
  display: none;
}
.folders-accordion__control + .folders-accordion-content {
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  margin: 0;
}
.folders-accordion__control:checked + .folders-accordion-content {
  max-height: none;
  transition: max-height 0.5s;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.folders-accordion__toggle {
  color: #203864;
  font-size: 1.5rem;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 1.5rem;
  cursor: pointer;
  position: relative;
}
.folders-accordion__title {
  font-size: 1.35rem;
  font-weight: bold;
  padding-left: 3rem;
  cursor: pointer;
  position: absolute;
  padding-bottom: 3.5rem;
}

.folders-accordion-content {
  width: 100%;
  padding: 0;
}
.folders-accordion-content__item {
  display: flex;
  padding: 0.8rem 2rem;
  justify-content: space-between;
  width: 25rem;
  height: 4rem;
  align-items: center;
}
.folders-accordion-content__item > span {
  display: none;
  color: #8497b0;
  border: 1px solid #8497b0;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.3rem 2rem;
  font-size: 1rem;
}
.folders-accordion-content__item:hover {
  background-color: white;
}
.folders-accordion-content__item:hover > span {
  display: block;
}

/*++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*     SPECIFIC STYLING FOR GRAPHS     */

.countries-stats {
  padding-top: 10px;
}
.countries-stats-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0rem 0rem 3rem 1rem;
  list-style-type: none;
  max-height: 33rem;
  overflow-y: auto;
}
@media only screen and (min-width: 1900px) and (max-width: 2500px) {
  .countries-stats-list {
    max-height: 40rem;
  }
}

@media only screen and (min-width: 2200px) {
  .countries-stats-list {
    max-height: 36rem;
  }
}

.countries-stats-list__item {
  border-bottom: 1px solid #bbb;
  overflow: hidden;
}
.countries-stats-list__item > a > img {
  width: 1.5rem;
  margin-right: 1rem;
}
.countries-stats-list__item > a {
  display: grid;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  color: #000;
  text-decoration: none;
  grid-template-columns: 1.5rem 1fr 2rem;
  grid-column-gap: 1rem;
  align-items: center;
}
.countries-stats-list__item > a:nth-child(odd) {
  padding: 0.5rem 3.5rem 0.5rem 1.5rem;
}
.countries-stats-list__item > a:hover {
  font-weight: bold;
}
.countries-stats-list__item > a > b {
  justify-self: flex-end;
  background-color: #eee;
  padding: 3px 6px;
  color: #203864;
  font-size: 1rem;
}

.entries-list {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  max-height: 28rem;
  overflow-y: auto;
}
.entries-list__item {
  display: grid;
  grid-template-columns: 2rem 1fr auto 2rem;
  grid-column-gap: 2rem;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid grey;
  align-items: center;
  color: #272d3b;
  text-decoration: none;
  cursor: pointer;
}
.entries-list__item > img {
  width: 100%;
}
.entries-list__item > b {
  background-color: #eee;
  padding: 3px 6px;
  color: #203864;
  font-size: 1rem;
}
.entries-list__item > p {
  text-decoration: none;
  width: 90%;
  max-height: 3rem;
  overflow: hidden;
}
.entries-list__item > button {
  width: 2rem;
  height: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 1rem 0;
  border: 0;
  outline: none;
  background-color: transparent;
  background-position: center;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  background-image: url("assets/svgs/go-to.svg");
}

.save-search-criteria-form {
  align-items: flex-start;
}
.save-search-criteria-form__input {
  margin: 0.6rem 0 2rem;
}

.css-yk16xz-control {
  border: 0 !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
}

.css-g1d714-ValueContainer {
  color: rgba(28, 28, 28, 0.45) !important;
  padding-left: 1.5rem !important;
  font-size: 1.35rem !important;
}

.css-1wa3eu0-placeholder {
  color: rgba(28, 28, 28, 0.45) !important;
}

/*     TERMS AND CONDITIONS PAGE     */
.split-bg {
  background: white;
  background: -moz-linear-gradient(180deg, white 0%, white 50%, #efefef 50%);
  background: -webkit-linear-gradient(180deg, white 0%, white 50%, #efefef 50%);
  background: linear-gradient(180deg, white 0%, white 50%, #efefef 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#efefef",GradientType=1);
}

.terms-and-conditions__title {
  font-size: 2.6rem;
  font-weight: bold;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  padding-bottom: 1rem;
  margin-bottom: 4rem;
  width: 100%;
}

.terms-and-conditions > .card--100 {
  padding: 6rem 7%;
  margin: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.card-row {
  display: grid;
  margin-bottom: 5rem;
  grid-template-columns: 30% 70%;
  grid-column-gap: 3rem;
}
.card-row:last-child {
  margin-bottom: 0rem;
}
.card-row__header {
  font-size: 1.5rem;
  font-weight: bold;
  padding-right: 20%;
}
.card-row__text {
  font-size: 1.2rem;
  columns: 1;
  column-gap: 3rem;
}

/*     CONTACT PAGE     */
.contact-page {
  padding: 10rem 15% 5rem;
  justify-content: space-evenly;
  align-items: flex-end;
}
.contact-page__info {
  margin-bottom: 4rem;
}
.contact-page__info > b {
  display: block;
  font-size: 2rem;
  margin-bottom: 1.2rem;
}
.contact-page__info > p {
  line-height: 1.5;
}

.contact-form {
  width: 60%;
  align-items: flex-start;
  padding: 0 5% 3rem;
  margin: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}
.contact-form__title {
  margin-top: 7rem;
  text-align: left;
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.contact-form__input {
  margin: 0.6rem 0;
}
.contact-form__textarea {
  margin-top: 0.6rem;
  height: 13rem;
}
.contact-form__submit {
  margin-bottom: 1.6rem;
  width: 10rem;
  align-self: flex-end;
}

/*     PROFILE PAGE     */
.profile-page {
  padding: 10rem 30% 5rem;
}

.profile-form {
  width: 100%;
  align-items: flex-start;
  padding: 3rem 10%;
  margin: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}
.profile-form__image {
  align-self: center;
  width: 12rem;
}
.profile-form__upload-btn {
  font-size: 1.25rem;
  background: #fff;
  color: #203864;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;
  transform: translate(3rem, -100%);
  font-weight: bold;
}
.profile-form__title {
  margin-top: 1rem;
  text-align: left;
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.profile-form__input {
  margin: 0.6rem 0;
  padding-left: 14rem;
  color: #484848;
  position: relative;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.profile-form__arrow::after {
  content: "";
  background-image: url("assets/svgs/caret-right.svg");
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(-50%);
  right: 1rem;
  cursor: pointer;
}
.profile-form__placeholder {
  position: relative;
  width: 100%;
}
.profile-form__placeholder::after {
  position: absolute;
  left: 1.52rem;
  top: 1.65rem;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.5;
  font-size: 1.3rem;
  font-family: "Lato", sans-serif;
}

/*     JURISDICTION AND TOPIC PAGES     */
.jurisdiction-title,
.topic-title {
  padding: 3.5rem 10% 1rem;
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-column-gap: 1rem;
  align-items: center;
}
@media only screen and (min-width: 1920px) and (max-width: 2500px) {
  .jurisdiction-title,
  .topic-title {
    padding: 3.5rem 0% 1rem;
    max-width: calc(1920px - 20%);
    margin: auto;
  }
}
@media only screen and (min-width: 2501px) {
  .jurisdiction-title,
  .topic-title {
    padding: 3.5rem 0% 1rem;
    max-width: 1920px;
    margin: auto;
  }
}
.jurisdiction-title > img,
.topic-title > img {
  grid-column: 1/2;
  width: 100%;
}
.jurisdiction-title > h1,
.topic-title > h1 {
  font-size: 3.3rem;
  grid-column: 2/3;
  grid-row: 1/2;
}
.jurisdiction-title > h1 > span,
.topic-title > h1 > span {
  color: #8497b0;
}
.jurisdiction-title > p,
.topic-title > p {
  font-size: 1.4rem;
  grid-column: 2/3;
  grid-row: 2/3;
  opacity: 0.6;
  margin-left: 2px;
}
.jurisdiction-title > p > span,
.topic-title > p > span {
  color: #8497b0;
}
.jurisdiction-title > hr,
.topic-title > hr {
  grid-column: 1/3;
  margin-top: 1rem;
  height: 1px;
  background-color: rgba(112, 112, 112, 0.5);
  border: 0;
}

.topic-title {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.topic-title > h1 {
  font-size: 3.3rem;
}
.topic-title > p {
  font-size: 1.4rem;
  opacity: 0.6;
}
.topic-title > hr {
  margin-top: 1rem;
  height: 1px;
  background-color: rgba(112, 112, 112, 0.5);
  border: 0;
  width: 100%;
}
.report-form button {
  margin-top: 10px;
}

.report-form a {
  text-decoration: none;
  color: #203864;
  font-weight: bold;
}
.report-form a:hover {
  text-decoration: underline;
  color: #203864;
  font-weight: bold;
}
.report-form a:visited {
  text-decoration: none;
  color: #203864;
  font-weight: bold;
}

.mark-used {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #256f9a;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  margin-top: 6px;
}

.dot {
  background-color: #fff;

  border: 1px solid black;
  height: 1.3rem;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 1.3rem;
  vertical-align: bottom;
  display: inline-block;
  cursor: pointer;
  margin-left: 6px;
}

.dot-used {
  background-color: #9e0b0f;
  border: 1px solid #9e0b0f;
  font-weight: bolder;
}

.profile-text {
  height: 3.8rem;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 1.6rem;
  margin-top: 10px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  color: rgba(28, 28, 28, 0.45);
  padding-left: 1.5rem;
  font-size: 1.35rem;
}
.profile-save-btn {
  height: 3.8rem;
  border-radius: 8px;
  padding: 0.8rem;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  width: 30%;
  background-color: #203864;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  border: 0;
  outline: none;
  font-size: 1.35rem;
  font-family: "Lato", sans-serif;
  float: right;
}
.profile-cancel-btn {
  height: 3.8rem;
  border-radius: 8px;
  padding: 0.8rem;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  width: 30%;
  background-color: #a0a0a0;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  border: 0;
  outline: none;

  font-size: 1.35rem;
  font-family: "Lato", sans-serif;
}

.profile-edit {
  width: 50%;
}

.profile-edit-btn {
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
}

.profile-error {
  color: red;
}
