.loading-gif {
  text-align: center;
  width: 100%;
  padding: 100px;
}
.loading-daughters-gif {
  text-align: center;
  width: 100%;
  padding: 0px;
}
.child-entry-container {
  margin-left: 10.4%;
  margin-right: 15%;
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
  margin-top: 10px;
}
.child-entry {
  margin-left: 10%;
  margin-right: 0%;
}

.entries-sorting {
  /*position: unset;*/
  z-index: 400;
}

.entries-sorting__dropdown p {
  width: 16rem;
}

.entries-sorting-options__option:hover {
  font-weight: bold;
}

.entries-accordion-content {
  padding-right: 5px;
  max-height: 800px;
  overflow: auto;

}



.entry__country-flag {
  vertical-align: middle;
}

.entries-accordion__title {
  user-select: none;
}

